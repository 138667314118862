<template>
<section> 
  <b-row class="title-section spacer">
    <div>
      <h1 class="font-weight-bold">
        {{ hero.title }}
      </h1>
      <p class="lead message">
        {{ hero.description }}
      </p>
      <b-link :to="{ name: 'auth-login' }" class="get-started-btn">
        <span class="ml-1 mr-1 font-weight-bold">
          Get Started
          <RightArrow class="ml-1" />
        </span>
      </b-link>
    </div>
    <div>
      <div v-if="hero.icon === 'exxtra-nss' ">
        <NssExxtra />
      </div>
      <ExxtraCash v-if="hero.icon === 'exxtra-cash' " />
      <ExxtraCheckout v-if="hero.icon === 'exxtra-checkout' " />
    </div>
  </b-row>
</section>
</template>

<script>
import { BLink, BRow, } from 'bootstrap-vue'

import NssExxtra from "@/pages/public/services/shared/FeaturedSvgs/NssExxtra.svg";
import ExxtraCash from "@/pages/public/services/shared/FeaturedSvgs/ExxtraCash.svg";
import ExxtraCheckout from "@/pages/public/services/shared/FeaturedSvgs/ExxtraCheckout.svg";

import RightArrow from "./FeaturedSvgs/RightArrow.svg"


export default {
  name: "Hero",
  components: {
    BRow,
    BLink,
    NssExxtra,
    RightArrow,
    ExxtraCash,
    ExxtraCheckout
  },
  props: {
    hero: {
      type: Object,
      required: true,
      default: () => ({ title: "", description: "", icon: "exxtra-cash" })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-public.scss';

.spacer {
  gap: 10rem;
  margin-top: 50px;

  @media (max-width: 768px) {
    gap: 1rem;
  }
}

#nss-exxtra-service-image {
  @media (max-width: 768px) {
    padding: 40px;
  }
}

.title {
  font-weight: 700; 
  font-size: 60px; 
  line-height: 78px;

  @media (max-width: 768px) {
    font-size: 40px; 
    line-height: 58px;
  }
}

.message {
  padding-right: 20px;

  @media (max-width: 768px) {
    text-align: center;
    width: 80%;
    margin: auto;
  }
}
</style>
