<template>
    <div>
    <!--  partners section-->
    <Partner />
    <!--  faqs-->
    <FAQs />
    <!--apply now -->
    <ApplyNow />
    <!-- The real footer -->
    <Footer />
    </div>
</template>

<script>
import Footer from "@/pages/public/footer.vue";
import ApplyNow from "@/pages/public/ApplyNow.vue";
import FAQs from "@/pages/public/FAQs.vue";
import Partner from "@/pages/public/Partner.vue";

export default {
    components: {
        Footer,
        ApplyNow,
        FAQs,
        Partner
    }
}
</script>

<style>

</style>
