<template>
  <div id="features" class="featured-container my-4">

    <h1 class="mb-3">
      Features
    </h1>

    <div class="w-100 d-flex justify-content-between mb-2 flex-wrap features-container">
      <ServiceCard
          v-for="service of services.slice(0,3)"
          :key="service.title"
          :title="service.title"
          :content="service.content"
          :icon="service.icon"
      />
    </div>

    <div class="w-100 d-flex justify-content-between features-container">
      <ServiceCard
          v-for="service of services.slice(3)"
          :key="service.title"
          :title="service.title"
          :content="service.content"
          :icon="service.icon"
      />
    </div>
  </div>
</template>

<script>
import { BCol, BRow } from "bootstrap-vue"
import ServiceCard from "../shared/ServiceCard.vue";

export default {
    components: {
        BCol, 
        BRow,
        ServiceCard
    },
    data() {
      return {
         services: [
          {
            title: "Pay in 3-6 installments",
            content: "Split the cost of your purchase into up to 6 payments.",
            icon: "MoneyTime"
          },
          {
            title: "Simple signup process",
            content: "Avoid the hassle of filling long <br /> forms, sign up in a few easy steps.",
            icon: "simple"
          },
          {
            title: "Short term loan",
            content: `You're in control. Pick the payment option that 
            works for you and your budget and start enjoying 
            what you've ordered right away.`,
            icon: "repayment"
          },
          {
            title: "Repayment",
            content: `Monthly Repayment via account deduction through 
            debit cards, Direct Debit and Mobile money. 
            Choose to repay your loan within 3- 6 months.`,
            icon: "money"
          }
        ]
      }
    }
}
</script>

<style lang="scss" scoped>

.featured-container {
    padding-left: 11rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-height: 400px; 
    justify-content: flex-start;

    @media (max-width: 768px) {
        padding-left: 7px;
        margin-left: 7px;
        margin-right: auto;
        justify-content: center;
        align-items: center;
    }
}

.feature-icon {
    display: flex;
    justify-content: center;
}

.features-container {
  @media (max-width: 768px) {
        flex-direction: column;
    }
}

</style>
