<template>
  <div class="d-flex align-items-center mb-1 w-100">
    <span class="mr-1 ">
        <CheckMark />
    </span>
    <p class="p-0 m-0 text-white" style="font-size: 16px; font-weight: 400; z-index: 25;">
        {{ label }}
    </p>
  </div>
</template>

<script>
import CheckMark from "./FeaturedSvgs/CheckMark.svg";

export default {
    name: "RequirementRow",
    components: {
        CheckMark
    },
    props: {
        label: {
            type: String,
            required: true,
            default: ""
        }
    }
}
</script>

<style>

</style>
