<template>
  <div id="requirements" class="title-section bg-primary requirement-section">
    <div class="d-flex flex-column justify-content-start w-100">
      <h1 style="color: #adcd39; margin-bottom: 20px">
        Requirements
      </h1>

      <div class="w-100">
        <requirement-row
          v-for="requirement in requirements"
          :key="requirement"
          :label="requirement"
        />
      </div>
    </div>

    <div
      class="d-flex justify-content-between w-100 my-4 requirements-svg"
    >
      <svg
        width="566"
        height="500"
        viewBox="0 0 566 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M212.489 0.95813H81.1191L600.856 525.003L725.048 519.26L520.455 313.949L725.048 102.896H600.856L452.975 246.469L419.953 215.601L626.7 0.95813H498.919L355.345 143.814L212.489 0.95813Z"
          fill="#ADCD39"
        />
        <path
          d="M125.627 102.896H0L203.157 308.206L0 509.21H125.627L267.765 374.25L300.787 402.965L98.3481 608.275H223.975L432.875 402.965L125.627 102.896Z"
          fill="#459042"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import RequirementRow from "./RequirementRow.vue";

export default {
  name: "Requirements",
  components: {
    RequirementRow,
  },
  props: {
    requirements: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.requirement-section {
  width: 100%;
  min-height: 500px;
  position: relative;

  @media (max-width: 768px) {
    padding: 12px !important;
  }
}

.requirements-svg {
  position: absolute;
  left: 71%;

  @media (max-width: 1600px) {
    left: 65%;
  }
}
</style>
