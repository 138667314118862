<template>
  <div class="mb-1 card-icon-container">
    <span class="">
        <component :is="icon" class="mb-1" />
    </span>
    <h2 style="font-style: normal;font-weight: 400;font-size: 20px; line-height: 30px; color: #144F6A;">
        {{ title }}
    </h2>
    <!-- eslint-disable vue/no-v-html -->
    <p style="color: #000000; font-weight: 200; font-size: 16px; line-height: 160%;" v-html="content">
    </p>
  </div>
</template>

<script>
import QuickProcessing from "./FeaturedSvgs/24-hours.svg";
import Simple from "./FeaturedSvgs/Simple.svg";
import Property from "./FeaturedSvgs/Property.svg";

import Repayment from "./FeaturedSvgs/Pay.svg";
import Flexible from "./FeaturedSvgs/Flexible.svg";
import Money from "./FeaturedSvgs/Money.svg";
import MoneyTime from "./FeaturedSvgs/MoneyTime.svg";


export default {
 components: {
     QuickProcessing,
     Repayment,
     Property,
     Money,
     Simple,
     Flexible,
     MoneyTime
 },
 props: {
     title: {
         type: String,
         required: true,
         default: ""
     },
     content: {
         type: String,
         required: true,
         default: ""
     },
     icon: {
        type: String,
        required: true,
        default: ""
    }
 }
}
</script>

<style lang="scss" scoped>
.card-icon-container {
    flex-basis: 30%;
    @media (max-width: 768px) {
       display: flex;
       flex-direction: column;
       justify-content: center;
       align-items: center;
    }

}
</style>
